import request from './request'

// 获取师傅列表
export const getmasterList = (data) => {
  return request.post('/rame/gsfms/getSF', data)
}
// 获取店铺列表
export const getstoreList = (data) => {
  return request.post('/rame/stores', data)
}

export const getAfterSalesList = (data) => {
  return request.post('/jf-tech/v1/getOrdersAfterSales', data)
}

export const editAfterSales = (data) => {
  return request.post('/jf-tech/v1/updateSaleInfo', data)
}

export const addGoods = (data) => {
  return request.post('/jf-tech/v1/addGoods', data)
}

export const delAfterSales = (data) => {
  return request.post('/jf-tech/v1/deleteGoods', data)
}

export const get3DBallData = (data) => {
  return request.get('/get3DBallData', data)
}

export const delImgItem = (data) => {
  return request.post('/jf-tech/v1/deleteGoodsImages', data)
}
