import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "warning-text"
};
const _hoisted_2 = {
  class: "warning-text"
};
const _hoisted_3 = {
  class: "warning-text"
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue';
import { Edit } from '@element-plus/icons-vue';
import { AllDict } from '@/utils/common';
import { editGoods, addGoods, delImgItem, getActivities, getGoodsTypes } from '@/api/goods';
import { ElMessage } from 'element-plus';
import ImageUpload from '@/components/ImageUpload';
import ModalSortsSetting from './modal-sorts-setting.vue';
const formLabelWidth = '80px';
export default {
  __name: 'modal-info',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const rules = ref({
      subject: [{
        required: true,
        message: '请输入商品标题',
        trigger: 'blur'
      }],
      specification: [{
        required: true,
        message: '请输入商品介绍',
        trigger: 'blur'
      }],
      unitPrice: [{
        required: true,
        message: '请输入单价',
        trigger: 'blur'
      }],
      type: [{
        required: true,
        message: '请选择商品分类',
        trigger: 'blur'
      }],
      onlineState: [{
        required: true,
        message: '请选择上架状态',
        trigger: 'blur'
      }],
      coverPhotoUrl: [{
        required: true,
        message: '请选择封面图',
        trigger: 'blur'
      }],
      adPhotoUrls: [{
        required: true,
        message: '请选择推广图',
        trigger: 'blur'
      }],
      detailsPhotoUrls: [{
        required: true,
        message: '请选择详情图',
        trigger: 'blur'
      }],
      stocks: [{
        required: true,
        message: '请输入库存量',
        trigger: 'blur'
      }]
    });

    const generateFrom = () => {
      return {
        goodsId: null,
        subject: '',
        specification: '',
        unitPrice: '',
        sales: '',
        stocks: '',
        coverPhotoUrl: '',
        adPhotoUrls: '',
        detailsPhotoUrls: '',
        onlineState: '1',
        activityId: null,
        mallActivityId: null,
        shopcity: null,
        deliverycity: null,
        region: null,
        discount: null,
        type: null,
        flagship: null,
        SFdelivery: null,
        deliveryInfo: '付款后24小时内发货'
      };
    };

    const formModel = reactive(generateFrom());
    const modalSortsSetting = ref();

    const handleSortsSetting = record => {
      modalSortsSetting.value.showModal(formModel);
    };

    const showModal = data => {
      if (!data.goodsId) {
        Object.assign(formModel, generateFrom());
      } else {
        Object.assign(formModel, data);
        Object.assign(formModel, {
          old_discountAmount: data.discountAmount,
          new_discountAmount: data.discountAmount,
          old_expenses: data.expenses,
          new_expenses: data.expenses
        });
      }

      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `${formModel.goodsId ? '编辑' : '新建'}商品 ${formModel.goodsId ? 'ID-' + formModel.goodsId : ''}`;
    }); // 删除

    const delImgItemFn = imgArr => {
      delImgItem({
        images: [].concat(imgArr)
      }).then(res => {
        if (res.status === 200) {
          ElMessage.success('删除成功');
        }
      });
    }; // 取消


    const cancelModal = () => {
      if (!formModel.goodsId) {
        const imgArr = [];
        formModel.coverPhotoUrl.split(',').map(item => {
          if (item) {
            const imgName = item.split('?image=')[1];
            imgArr.push(imgName);
          }
        });
        formModel.adPhotoUrls.split(',').map(item => {
          if (item) {
            const imgName = item.split('?image=')[1];
            imgArr.push(imgName);
          }
        });
        formModel.detailsPhotoUrls.split(',').map(item => {
          if (item) {
            const imgName = item.split('?image=')[1];
            imgArr.push(imgName);
          }
        });
        delImgItemFn(imgArr);
      }

      dialogVisible.value = false;
    }; // 新建商品


    const addGoodsFn = async () => {
      try {
        if (formModel.type === '全部') {
          formModel.type = null;
        }

        delete formModel.goodsId;
        const resData = await addGoods(formModel);

        if (resData && resData.status === 'success') {
          ElMessage({
            message: '新建成功！',
            type: 'success'
          });
          emit('refresh');
          hideModal();
        }
      } catch (err) {
        console.log(err);
      }
    }; // 编辑商品


    const editOrderFn = async () => {
      console.log('编辑');
      console.log(formModel);

      try {
        if (formModel.type === '全部') {
          formModel.type = null;
        }

        const resData = await editGoods(formModel);

        if (resData && resData.status === 'success') {
          ElMessage({
            message: '编辑成功！',
            type: 'success'
          });
          emit('refresh');
          hideModal();
        }
      } catch (err) {
        console.log(err);
      }
    }; // 保存按钮


    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');

          if (fields) {
            return;
          }

          if (formModel.goodsId) {
            editOrderFn();
          } else {
            addGoodsFn();
          }
        } else {
          console.log('error submit!', fields);
        }
      });
    }; // 获取活动信息


    const activitiesArr = ref([]); // 平台活动信息

    const mallActivitiesArr = ref([]);

    const getActivitiesFn = async type => {
      try {
        const resData = await getActivities({
          type: type // 查询所有的商品活动
          // 1 商品活动，2 服务活动，3 平台活动，4 VIP活动

        });

        if (resData.status === 'success') {
          if (type === 1) {
            activitiesArr.value = resData.data;
          } else if (type === 3) {
            mallActivitiesArr.value = resData.data;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }; // 商品类型


    const shopTypes = ref([]);

    const getGoodsTypesFn = async type => {
      try {
        const resData = await getGoodsTypes({});

        if (resData.status === 'success') {
          shopTypes.value = resData.data.typesName.split(',');
        }
      } catch (err) {
        console.log(err);
      }
    }; // 获取活动信息


    getActivitiesFn(1);
    getActivitiesFn(3);
    getGoodsTypesFn(); // 图片

    const getdetailfileList = e => {
      console.log('上传图片回传');
      console.log(e);
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_button = _resolveComponent("el-button");

      const _component_InfoFilled = _resolveComponent("InfoFilled");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "50%",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createVNode(_component_el_button, {
          onClick: cancelModal
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[19] || (_cache[19] = $event => submitForm(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef,
          rules: rules.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商品标题",
            "label-width": formLabelWidth,
            prop: "subject"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.subject,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.subject = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "商品介绍",
                "label-width": formLabelWidth,
                prop: "specification"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.specification,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.specification = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "单价",
                "label-width": formLabelWidth,
                prop: "unitPrice"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.unitPrice,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.unitPrice = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "销售量",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.sales,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.sales = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "库存量",
                prop: "stocks",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.stocks,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.stocks = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "上架状态",
                prop: "onlineState",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.onlineState,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.onlineState = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['onlineStateDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "会员折扣",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.discount,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.discount = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['discount'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "发货地",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.deliverycity,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formModel.deliverycity = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "发货类型",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.region,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formModel.region = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['regionDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "品牌直营",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.flagship,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => formModel.flagship = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['isDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "商铺所在城市",
                "label-width": "100px"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.shopcity,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => formModel.shopcity = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 8
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "顺丰包邮",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.SFdelivery,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => formModel.SFdelivery = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['isDict'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "商品分类",
                prop: "type",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.type,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => formModel.type = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(AllDict)['shopTypes'], (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: item.label,
                      value: item.value,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "发货信息",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.deliveryInfo,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => formModel.deliveryInfo = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "商品活动",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.activityId,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => formModel.activityId = $event),
                  placeholder: "请选择",
                  style: {
                    "width": "320px"
                  }
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "不参加活动",
                    value: 0
                  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activitiesArr.value, (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: `${item.title}-${item.content}`,
                      value: item.activityId,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "平台活动",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.mallActivityId,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => formModel.mallActivityId = $event),
                  placeholder: "请选择",
                  style: {
                    "width": "320px"
                  }
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "不参加活动",
                    value: 0
                  }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mallActivitiesArr.value, (item, index) => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      label: `${item.title}-${item.content}`,
                      value: item.activityId,
                      key: index
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "型号配置",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_button, {
                  size: "small",
                  type: "success",
                  icon: _unref(Edit),
                  onClick: handleSortsSetting
                }, {
                  default: _withCtx(() => [_createTextVNode("编辑种类")]),
                  _: 1
                }, 8, ["icon"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "封面图",
                prop: "coverPhotoUrl",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_unref(ImageUpload), {
                  modelValue: formModel.coverPhotoUrl,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => formModel.coverPhotoUrl = $event),
                  limit: 1
                }, null, 8, ["modelValue"]), _createElementVNode("span", _hoisted_1, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_component_InfoFilled)]),
                  _: 1
                }), _createTextVNode(" 封面图 最多1张，尺寸限制：660*660，图片大小在2M以内 ")])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "推广图",
                prop: "adPhotoUrls",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_unref(ImageUpload), {
                  modelValue: formModel.adPhotoUrls,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => formModel.adPhotoUrls = $event),
                  limit: 5
                }, null, 8, ["modelValue"]), _createElementVNode("span", _hoisted_2, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_component_InfoFilled)]),
                  _: 1
                }), _createTextVNode(" 推广图 最多5张，尺寸限制：660*660，图片大小在2M以内 ")])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "详情图",
                prop: "detailsPhotoUrls",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_unref(ImageUpload), {
                  modelValue: formModel.detailsPhotoUrls,
                  "onUpdate:modelValue": [_cache[18] || (_cache[18] = $event => formModel.detailsPhotoUrls = $event), getdetailfileList],
                  limit: 10,
                  whlimit: [600, 300],
                  "more-height": true
                }, null, 8, ["modelValue"]), _createElementVNode("span", _hoisted_3, [_createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [_createVNode(_component_InfoFilled)]),
                  _: 1
                }), _createTextVNode(" 详情图 最多10张，尺寸限制：宽度必须为600，高度在300～1000以内，图片大小在2M以内 ")])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(ModalSortsSetting, {
        ref_key: "modalSortsSetting",
        ref: modalSortsSetting
      }, null, 512)], 64);
    };
  }

};